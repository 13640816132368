

















import { Component, Prop, Vue } from 'vue-property-decorator';

import DataListFiltersWrapper from '@/shared/resources/components/data-list/DataListFiltersWrapper.vue';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Loader from '@/shared/resources/components/Loader.vue';

import ORMCollection from '@/core/bridge/orm/ORMCollection';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
    DataListFiltersWrapper,
    MainHeader,
    Loader,
  },
})
export default class DataList extends Vue {
  /**
   * Props
   */
  @Prop() private collection!: ORMCollection;
  @Prop({default: 'Błąd wczytywania danych.'}) private errorTitle!: string | null;
  @Prop() private errorMessage!: string | null;

  /**
   * Data
   */
  private filtersModel: any = {}; // TODO

  /**
   * Decide to display table
   */
  private get displayTable(): boolean {
    return !this.errorMessage && this.collection.isInjected;
  }

  /**
   * Decide to display loader
   */
  private get displayLoader(): boolean {
    return !this.collection.isInjected && !this.errorMessage;
  }
}
